import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// Model imports
import { Injectable } from "@angular/core";

import { AccessService } from "@core/services/access.service";
import { CAuthService } from "@core/services/auth.service";
import { HotjarService } from "@core/services/hotjar.service";

import { Observable } from "rxjs";
import * as uuid from "uuid";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseURL = environment.APIv1Endpoint;

  endpoint = environment.APIv2Endpoint;

  spec = null;
  session: string = null;

  constructor(
    protected http: HttpClient,
    private authService: CAuthService,
    public accessService: AccessService,
    protected hj: HotjarService,
  ) {
    this.authService.onAuthChange$.subscribe((_) => {
      this.setSession();
    });

    this.accessService.accessChanged$.subscribe((access) => {
      if (access == null || access?.account != null) {
        this.setSession(true);
      }
    });
  }

  setSession(clear = false) {
    let s = sessionStorage.getItem("session");
    if (!s || clear) {
      s = uuid.v4();
      sessionStorage.setItem("session", s);
    }
    this.session = s;
  }

  params(toSet) {
    let res = new HttpParams();

    Object.keys(toSet).forEach((key) => {
      res = res.set(key, toSet[key]);
    });

    return { params: res };
  }

  arrayGetParams(a): HttpParams {
    let params = new HttpParams();
    for (const k of Object.keys(a)) {
      const s = a[k];
      if (s.size === 0) {
        continue;
      }

      s.forEach((v) => {
        params = params.append(k, v);
      });
    }
    return params;
  }

  public getHttpParams(params: object): HttpParams {
    if (!params) {
      return new HttpParams();
    }
    const fromString = Object.entries(params)
      .filter(([_, value]) => this.valuesFiltering(value))
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return new HttpParams({ fromString });
  }

  private valuesFiltering(value): boolean {
    return (
      (!Array.isArray(value) && value != null) ||
      (Array.isArray(value) && !!value.length)
    );
  }

  // no usage
  // getAvaliableFonts(): Observable<any> {
  //   return this.http.get(this.baseURL + "/media/font");
  // }

  // getLayerPreview(spec, productFilter = null): Observable<any> {
  //   const job = this.enqueueLayerPreview(spec, productFilter);
  //   return this.jobObserver(job);
  // }

  // no usage
  // getAvaliableBrands(): Observable<any> {
  //   return this.http.get(this.baseURL + "/feeds/1/lookup_data/brand");
  // }

  // no usage
  // getAvaliableCategories(): Observable<any> {
  //   return this.http.get(this.baseURL + "/feeds/1/lookup_data/category");
  // }

  // no usage
  // getProductsFromGroupings(grouping, value): Observable<any> {
  //   return this.http.get(
  //     this.baseURL + "/feeds/1/product" + "?" + grouping + "=" + value
  //   );
  // }

  // no usage
  // getProductsFromIds(productIds: number[]): Observable<Product[]> {
  //   return this.http.post<Product[]>(`${this.baseURL}/products/ids`, {
  //     product_ids: productIds,
  //   });
  // }

  // no usage
  // getDeliveries(): Observable<any> {
  //   return this.http.get(this.baseURL + "/deliveries");
  // }

  putFileToS3(body: File, presignedUrl: string) {
    const options = {
      headers: new HttpHeaders({}),
    };
    return this.http.put(presignedUrl, body, options);
  }

  getYoutube(): Observable<any> {
    return this.http.get(this.baseURL + "/meta/videos");
  }

  // Proctor
  getProctorCompaniesInfo(): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/proctor-companies`);
  }

  // Screen tracking
  trackScreen(path): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/users/track-screen`,
      {
        path: path,
      },
    );
  }

  hubspotIdentify(): Observable<{ email: string; token: string }> {
    return this.http.get<{ email: string; token: string }>(
      `${this.endpoint}/meta/hubspot_identify`,
    );
  }

  hubspotChatStarted(conversationID: string | number, path: string | null) {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/meta/hubspot/chat_started`,
      { id: `${conversationID}`, path: path },
    );
  }
}
